import React, { useState, useEffect } from 'react';
import './App.css';
import { HashRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

//  Startpaginas
import Home from './pages/home';
import Activities from './pages/activities';
import Contact from './pages/contact';
import AboutUs from './pages/aboutus';
import LoginPage from './pages/login';
import RegisterPage from './pages/register';
import Profiel from './pages/profiel';
import NewActivity from './pages/nieuwe_activiteit';
import Profielen from './pages/profielen';
import WebFrame from './pages/webframe';

// Teams
import Overzicht from './pages/overzicht/overzicht';
import AcuteGeneeskunde from './pages/acutegeneeskunde/acutegeneeskunde';
import Anatomie from './pages/anatomie/anatomie';
import Radiologie from './pages/radiologie/radiologie';
import Basisvaardigheden from './pages/basisvaardigheden/basisvaardigheden';
import Duurzaam from './pages/duurzaam/duurzaam';
import Leefstijlgeneeskunde from './pages/leefstijlgeneeskunde/leefstijlgeneeskunde';
import Obstetrie from './pages/obstetrie/obstetrie_gynaecologie';
import Psychiatrie from './pages/psychiatrie/psychiatrie';
import Wetenschap from './pages/wetenschap/wetenschap';
import Promo from './pages/promo/promo';
import YuShrine from './pages/yushrine';
import TermsAndServices from './pages/TermsAndServices';

// Basisvaardigheden
import Venapunctie from './pages/basisvaardigheden/venapunctie';
import Hechten from './pages/basisvaardigheden/hechten';
import STAT from './pages/basisvaardigheden/stat';


import logo from './images/logo.png'; // Voeg hier het pad naar je logo toe
import instagramLogo from './images/instagramLogo.png'


const App: React.FC = () => {

  // Retrieving the login
  const [studentnummer, setStudentnummer] = useState('');
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    // Get the username from the logged-in user
    const studentnummer_string = localStorage.getItem("studentnummer") || "";
    const studentnummer_cache = studentnummer_string.replace(/"/g, "");

    // admin status ophalen
    const admin_string = localStorage.getItem("admin") || "";
    const admin_cache = admin_string.replace(/"/g, "");
    const admin = admin_cache === '70f076a4e37da1d0cc8e285966979c2' ? true : false;
    setAdmin(admin);

    // Update the username state variable
    setStudentnummer(studentnummer_cache);
  }, []);

  console.log('this is excecuted')
  console.log(studentnummer)

  

  return (
    <Router>
      <div className='root'>
        <div className="app">
          <nav>
            <div className='logo'>
            <Link to="/" className="logo-link">
              <img src={logo} alt="SpringLab Logo" className="logo" />
            </Link>
            </div>

            <div className='nav-items-mobile'>

              <ul className="nav-list">
                <div className='mobile-logo'>
                <Link to="/" className="logo-link">
                  <img src={logo} alt="SpringLab Logo" className="mobile-logo" />
                </Link>
                </div>
                <li className="nav-item">
                  <Link to="/activities">Agenda</Link>
                </li>
                <li className="nav-item">
                  {studentnummer ? <Link to="/profiel"> {studentnummer} </Link> : <Link to="/login">Login</Link>}
                </li>
                <li className="nav-item">
                  <Link to="/content">Content</Link>
                </li>
                <li className="nav-item">
                  <Link  target="_blank" to="https://www.instagram.com/springlabvu/"><img className='instagram-logo' src={instagramLogo} alt="Instagram" /></Link>
                </li>
              </ul>
            </div>  

            <div className='nav-items'>
              <ul className="nav-list">
                <li className="nav-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link to="/content">Content</Link>
                </li>
                {admin && (
                  <li className="nav-item">
                    <Link to="/profielen">Profielen</Link>
                  </li>

                )}
                <li className="nav-item dropdown">
                  <span className="dropdown-button">Teams</span>
                <ul className="dropdown-menu">
                  <li>
                    <Link to="/teams/overzicht">Ovezicht</Link>
                  </li>
                  <li>
                    <Link to="/teams/acute-geneeskunde">Acute geneeskunde</Link>
                  </li>
                  <li>
                    <Link to="/teams/anatomie">Anatomie & Fysiologie</Link>
                  </li>
                  <li>
                    <Link to="/teams/basisvaardigheden">Basisvaardigheden</Link>
                  </li>
                  <li>
                    <Link to="/teams/duurzaam">Duurzaamheid</Link>
                  </li>
                  <li>
                    <Link to="/teams/leefstijlgeneeskunde">Leefstijlgeneeskunde</Link>
                  </li>
                  <li>
                    <Link to="/teams/obstetrie_gynaecologie">Obstetrie & Gynaecologie</Link>
                  </li>
                  <li>
                    <Link to="/teams/psychiatrie">Psychiatrie en Ontwikkeling</Link>
                  </li>
                  <li>
                    <Link to="/teams/radiologie">Radiologie</Link>
                  </li>
                  <li>
                    <Link to="/teams/wetenschap">Wetenschap</Link>
                  </li>
                  <li>
                    <Link to="/teams/promo">Promotie</Link>
                  </li>
                </ul>
              </li>
                <li className="nav-item">
                  <Link to="/activities">Activiteiten</Link>
                </li>
                <li className="nav-item">
                  <Link to="/contact">Contact</Link>
                </li>
                <li className="nav-item">
                  <Link  target="_blank" to="https://linktr.ee/SpringLabVU">LinkTree</Link>
                </li>
                <li className="nav-item">
                  {studentnummer ? <Link to="/profiel"> {studentnummer} </Link> : <Link to="/login">Login</Link>}
                </li>
                <li className="nav-item">
                  <Link  target="_blank" to="https://www.instagram.com/springlabvu/"><img className='instagram-logo' src={instagramLogo} alt="Instagram" /></Link>
                </li>
              </ul>
            </div>  
          </nav>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/login" Component={LoginPage} />
            <Route path="/register" Component={RegisterPage} />
            <Route path="/activities" Component={Activities} />
            <Route path="/contact" Component={Contact} />
            <Route path="/aboutus" Component={AboutUs} />            
            <Route path="/yulam" Component={YuShrine} />            
            <Route path="/profiel" Component={Profiel} />
            <Route path="/newactivity" Component={NewActivity} />
            <Route path="/Profielen" Component={Profielen} />
            <Route path="/content" Component={WebFrame}/>
            <Route path="/TermsAndServices" Component={TermsAndServices} />
            <Route path="/teams/overzicht" Component={Overzicht} />
            <Route path="/teams/acute-geneeskunde" Component={AcuteGeneeskunde} />
            <Route path="/teams/anatomie" Component={Anatomie} />
            <Route path="/teams/radiologie" Component={Radiologie} />
            <Route path="/teams/basisvaardigheden" Component={Basisvaardigheden} />
            <Route path="/teams/duurzaam" Component={Duurzaam} />
            <Route path="/teams/leefstijlgeneeskunde" Component={Leefstijlgeneeskunde} />
            <Route path="/teams/obstetrie_gynaecologie" Component={Obstetrie} />
            <Route path="/teams/psychiatrie" Component={Psychiatrie} />
            <Route path="/teams/wetenschap" Component={Wetenschap} />
            <Route path="/teams/promo" Component={Promo} />
            <Route path="/teams/basisvaardigheden/venapunctie" Component={Venapunctie} />
            <Route path="/teams/basisvaardigheden/hechten" Component={Hechten} />
            <Route path="/teams/basisvaardigheden/STAT" Component={STAT} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;