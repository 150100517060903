import React from 'react';
import logo from './basis_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


import Venapunctie from './venapunctie';

const Radiologie: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Basisvaardigheden</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Bij team Basisvaardigheden houden we ons bezig met het aanleren van klinische vaardigheden. Doorgaans zijn dit skills die voor iedere toekomstig basisarts handig zal zijn. Denk hierbij bijvoorbeeld aan venapunctie, infuus prikken, hechten, katheteriseren en het beoordelen van ECG’s.
          <br /><br />
          Enerzijds is het fijn voor studenten om meer te kunnen oefenen met deze vaardigheden voordat ze de praktijk ingaan. Anderzijds ervaren veel coassistenten dat er in de praktijk soms te weinig mogelijkheden zijn om te oefenen. Desalniettemin wordt je als basisarts je vaak geacht deze vaardigheden te beheersen.
          <br /><br />
          Als student-assistent ben je betrokken met het ontwikkelen van lessen en verantwoordelijk voor het uitvoeren van lessen met andere student-assistenten. Vaak worden lessen gegeven in de vorm van workshops. We werken met het principe “See one, do one, teach one”. Student-assistenten worden ingewerkt tijdens een voorbereidingsles of een reguliere workshop. Pas wanneer je bekwaam wordt geacht, zal je ingedeeld worden om les te geven.
          </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop basis" to="./venapunctie">Venapunctie</Link>
          <Link className="knop basis" to="./">Infuus Prikken (TBA)</Link>
          <Link className="knop basis" to="./">Infuusbeleid (TBA)</Link>
          <Link className="knop basis" to="./hechten">Hechten</Link>
          <Link className="knop basis" to="./">ECG (TBA)</Link>
          <Link className="knop basis" to="./STAT">STAT</Link>
          
        </div>
      </div> */}
      {/* <button className="knop">STAT</button> */}
    </div>
  );
};

export default Radiologie;
