import React, { useState, useEffect } from 'react';
import { useNavigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import placeholder from './page-images/male-doctor-placeholder.png';
import silver from './page-images/silver_badge.png';
import gold from './page-images/gold_badge.png';

const Profiel: React.FC = () => {

  const navigate = useNavigate();

  const handleClearLocalStorage = () => {
    localStorage.clear();
    navigate('/', { replace: true });
    window.location.reload();
  };

  const [naam, setNaam] = useState("");
  const [studentnummer, setStudentnummer] = useState("");
  const [email, setEmail] = useState("");
  const [studie, setStudie] = useState("");
  const [studiejaar, setStudiejaar] = useState(null);
  const [badges, setBadges] = useState([
    {
      id: 1,
      naam: "Zilver badge",
      afbeelding: silver,
    },
    {
      id: 2,
      naam: "Gouden badge",
      afbeelding: gold,
    },
  ]);


  useEffect(() => {

    const fetchData = async () => {
      const studentnummer_string = localStorage.getItem("studentnummer") || "";
      const studentnummer_cache = studentnummer_string.replace(/"/g, "");

      try {
        const response = await fetch('https://SpringLabVU.pythonanywhere.com/get_profile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(studentnummer_cache),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const jsonData = await response.json();
        console.log(jsonData)
        setEmail(jsonData.email)
        setBadges(jsonData.badges)
        setNaam(jsonData.naam)
        setStudie(jsonData.studie)
        setStudiejaar(jsonData.year)
        setStudentnummer(jsonData.studentnummer)
      } catch (error) {
        // Handle any errors here
        console.error('Error:', error);
      }
    };
    // Call the API only if data is not already loaded
    if (!studiejaar) {
      fetchData();
    }
  }, [studiejaar]); // Only run the effect when the "data" state changes


  




  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
        {/* <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/> */}
      </div>
      <div className='pagina-titel'>
        <h1>Profiel</h1>
      </div>
      <div className='profiel-pagina-tekst'>
      <div className="profielfoto">
        <img src={placeholder} className="profielfoto"  alt="Profielfoto" />
      </div>
      <div className="profielgegevens">
        <h3 className="profieltitel">Naam</h3>
        <p className="profieltekst">{naam}</p>
        <h3 className="profieltitel">Studentnummer</h3>
        <p className="profieltekst">{studentnummer}</p>
        <h3 className="profieltitel">E-mail</h3>
        <p className="profieltekst">{email}</p>
        <h3 className="profieltitel">Studie</h3>
        <p className="profieltekst">{studie}</p>
        <h3 className="profieltitel">Studiejaar</h3>
        <p className="profieltekst">{studiejaar}</p>
      </div>
      {/* <div className="badges">
        {badges.map((badge) => (
          <img
            key={badge.id}
            src={badge.afbeelding}
            alt={badge.naam}
          />
        ))}
      </div> */}
      <button className='uitlogknop' onClick={handleClearLocalStorage}>Uitloggen</button>
      </div>

      {/* Lessen */}
      <div className='lessen'>
        <hr className='leslijn' />
        <div className="knoppenlijst">
          
          {/* <button className="knop">STAT</button> */}
        </div>
      </div>

    </div>
  );
};

export default Profiel;