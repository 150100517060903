import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './page-css/Activiteiten.css'; // Maak dit CSS-bestand voor styling

import AcuteGeneeskundeImage from './acutegeneeskunde/acute_logo.png';
import AnatomieImage from './anatomie/anatomie_logo.png';
import RadiologieImage from './radiologie/radiologie_logo.png';
import BasisvaardighedenImage from './basisvaardigheden/basis_logo.png';
import DuurzaamImage from './duurzaam/duurzaam_logo.png';
import LeefstijlgeneeskundeImage from './leefstijlgeneeskunde/leefstijl_logo.png';
import ObstetrieImage from './obstetrie/obstetrie_logo.png';
import PsychiatrieImage from './psychiatrie/psychiatrie_logo.png';
import WetenschapImage from './wetenschap/wetenschap_logo.png';
import PromoImage from './promo/promo_logo.png';

const activities = [
  {
    date: '2024-01-13',
    time: '19:00',
    location: 'KTC 4-006',
    title: 'Cursus: Serveronderhoud',
    description: 'Er werkt iets niet op de achtergrond, neem contact op met de beheerder.',
    participants: ['Wiwa', 'Faberyeyo', 'Baksteen', 'Bas Bron'],
    joined: true,
    active: true,
    slots: 5,
    team: 'AcuteGeneeskunde'
  },
];



const teamImages: Record<string, string> = {
  AcuteGeneeskunde: AcuteGeneeskundeImage,
  Anatomie: AnatomieImage,
  Radiologie: RadiologieImage,
  Basisvaardigheden: BasisvaardighedenImage,
  Duurzaam: DuurzaamImage,
  Leefstijlgeneeskunde: LeefstijlgeneeskundeImage,
  Obstetrie: ObstetrieImage,
  Psychiatrie: PsychiatrieImage,
  Wetenschap: WetenschapImage,
  Promo: PromoImage,
};


const Activities: React.FC = () => {

  const [activitiesData, setActivitiesData] = useState(activities);
  const studentnummer_string = localStorage.getItem("studentnummer") || "";
  const studentnummer_cache = studentnummer_string.replace(/"/g, "");
  const login = studentnummer_cache.length === 0 ? false : true;

  // naam van de persoon
  const naam_string = localStorage.getItem("naam") || "";
  const naam_cache = naam_string.replace(/"/g, "");

  // admin status ophalen
  const admin_string = localStorage.getItem("admin") || "";
  const admin_cache = admin_string.replace(/"/g, "");
  const admin = admin_cache === '70f076a4e37da1d0cc8e285966979c2' ? true : false;

  // email ophalen
  const email_string = localStorage.getItem("email") || "";
  const email_cache = email_string.replace(/"/g, "");
  console.log(email_cache)

  // Blacklist status

  useEffect(() => {
    fetch('https://SpringLabVU.pythonanywhere.com/activity_retrieval') 
    // fetch('http://127.0.0.1:5000/activity_retrieval') 
      .then(response => response.json())
      .then(data => {

        for (let i = 0; i < data.length; i++) {
          const activity = data[i];
          if (activity.participants.includes(naam_cache)) {
            activity.joined = true;
          }
          else {
            activity.joined = false;
          }
        }
        setActivitiesData(data);
      })
      .catch(error => {
        console.error(error);
      });

  }, []);

  const [response, setResponse] = useState('');
  

  const handleInschrijven = async (index: number, title: string) => {
    const response = await fetch('https://SpringLabVU.pythonanywhere.com/join_activity', {
    // const response = await fetch('http://127.0.0.1:5000/join_activity', {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        naam_cache,
        studentnummer_cache,
        index,
        title
      }),
    });

    const data = await response.json();
    setResponse(data);

    const activity = activitiesData[index];

    // Verzend een email 
    try {
      const response = await fetch('https://SpringLabVU.pythonanywhere.com/send_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            name: naam_cache,
            email: email_cache,
            phone: 'nvt',
            message: '',
            type: 'activiteit',
            activityName: activity.title,
            activityDate: activity.date,
            activityTime: activity.time,
            activityLocation: activity.location}),
      });

      if (response.ok) {
        console.log('Message sent successfully!');
      } else {
        console.error('Failed to send message.');
      }
    } catch (error) {
      console.error('Error:', error);
    }

    // herlaad de pagina na het aanmelden
    window.location.reload();
  };


  const handleUitschrijven = async (index: number, title: string) => {
    const response = await fetch('https://SpringLabVU.pythonanywhere.com/leave_activity', {
    // const response = await fetch('http://127.0.0.1:5000/leave_activity', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        naam_cache,
        studentnummer_cache,
        index,
        title
      }),
    });

  const data = await response.json();
  setResponse(data);

  window.location.reload();
  };

  const handleVerwijderen = async (index: number, title: string) => {
    const response = await fetch('https://SpringLabVU.pythonanywhere.com/remove_activity', {
    // const response = await fetch('http://127.0.0.1:5000/remove_activity', {    
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        naam_cache,
        studentnummer_cache,
        index,
        title
      }),
    });

  const data = await response.json();
  setResponse(data);

  window.location.reload();
  };

  // 
  function findNaamCacheIndex(participants: string[], naamCache: string): number {
    // Find the index of naam_cache in the participants list
    const index = participants.indexOf(naamCache);
  
    // Return the index or -1 if not found
    return index;
  }

  const formatDate = (dateString: string): string => {
    // Assuming the input date string is in the format "YYYY-MM-DD"
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}`;
  };

  const handleCopyEmails = async (participants: string[]) => {
    try {
      const response = await fetch('https://SpringLabVU.pythonanywhere.com/get_participant_emails', {
        // Replace with your actual endpoint for retrieving participant emails
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          participants,
        }),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        // Copy the email addresses to the clipboard
        const emailsToCopy = data.emails.join(', ');
  
        // Trigger clipboard operation only within a user-initiated event
        const textarea = document.createElement('textarea');
        textarea.value = emailsToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
  
        alert('Email addressen gekopieerd!');
      } else {
        console.error('Failed to retrieve participant emails.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  

  return (
    <div className="activities-container">
      <h1 className="page-title">Activiteiten</h1>
      {login === false && (
        <div className='activity-text'>
          <p>Login om je in te schrijven voor een cursus.</p>
        </div>
      )}
      <div className="activity-list">

        {activitiesData
        .filter(activity => activity.active)
        .map((activity, index, active) => (

          <div key={index} className="activity">

            <div className="activity-date">{formatDate(activity.date)} <br></br> {activity.time} <br></br> {activity.location}</div>
            <div className="activity-details">
              <h2>{activity.title}</h2>
              <p>{activity.description}</p>
              
              {login && admin && (<p>Deelnemers: {activity.participants.join(", ")}</p>)}
              {(activity.joined ? <div>Je staat momenteel ingeschreven op plaats {activity.participants.indexOf(naam_cache) + 1}/{activity.slots ? activity.slots : 'x'}.</div> : <div></div>)}
              <div className='activity-buttons'>
              {login && (activity.joined ? <button className='inschrijfknop' onClick={() => handleUitschrijven(index, activity.title)}>Uitschrijven</button> : <button className='inschrijfknop' onClick={() => handleInschrijven(index, activity.title)}>Inschrijven</button>)}
              {login && admin && (<button className='verwijderknop' onClick={() => handleVerwijderen(index, activity.title)}>Verwijderen</button>)}
              {login && admin && (<div><button className='mailknop' onClick={() => handleCopyEmails(activity.participants)}>Kopiëer mail</button></div>)}
              </div>
            </div>
            {activity.team && <img src={teamImages[activity.team]} alt={activity.team} className="team-image" />}
          </div>
        ))}

        {/* Knop om nieuwe lessen aan te maken */}
        {login && admin && (<>

          <div className='centercontainer'>
            <Link to="/newactivity">
              <button className='nieuweactiviteitknop'>Nieuwe activiteit aanmaken</button>
            </Link>
          </div>
        </>)}

        {/* Inactief voor de admins oude lessen zien */}
        {login && admin && (<>
        <hr className='leslijn' />
        <h2 className='lestitel'>Inactief</h2>

          {activitiesData
          .filter(activity => activity.active == false)
          .map((activity, index, active) => (

            <div key={index} className="activity">
              <div className="activity-date">{activity.date}</div>
              <div className="activity-details">
                <h2>{activity.title}</h2>
                <p>{activity.description}</p>
                {login && admin && (<p>Deelnemers: {activity.participants.join(", ")}</p>)}
                {/* {login && (activity.joined ? <button className='inschrijfknop' onClick={() => handleUitschrijven(index, activity.title)}>Uitschrijven</button> : <button className='inschrijfknop' onClick={() => handleInschrijven(index, activity.title)}>Inschrijven</button>)} */}
                {/* {login && admin && (<button className='verwijderknop' onClick={() => handleVerwijderen(index, activity.title)}>Verwijder Activiteit</button>)} */}
              </div>
              {activity.team && <img src={teamImages[activity.team]} alt={activity.team} className="team-image" />}
            </div>
          ))}

        </>)}
      </div>
    </div>
  );
};

export default Activities;
