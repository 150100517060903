import React from 'react';
import logo from './radiologie_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Radiologie: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
        <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Radiologie Team</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Radiologie is het medisch specialisme dat zich bezighoudt met het opzoeken van de aard en de plaats van een ziekte, letsel of aandoening door middel van stralen of golven. De meest gebruikte straling is röntgenstraling (röntgenfoto's, CT-scan), maar tegenwoordig wordt ook meer en meer gebruikgemaakt van ultrasone geluidsgolven (echografie) en magnetische velden (MRI-scan)
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop radio" to="./">Echografie</Link>
          <Link className="knop radio" to="./">CT-Scan</Link>
          <Link className="knop radio" to="./">MRI</Link>
          <Link className="knop radio" to="./">Nucleair (TBA)</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Radiologie;
