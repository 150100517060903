import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const InfoPagina: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-titel'>
        <h1>STAT</h1>
      </div>
      <div className='pagina-tekst'>
        De 'stations-toetsen', beter bekend als De STAT, zijn jaarlijkse toetsen aan het eind van ieder Bachelorjaar waarin de basisvaardigheden van de arts in spé worden getoetst. De toetsen zijn globaal onderverdeeld in 5 terugkerende hoofdonderdelen (wisselend per jaar):
        <br /><br />
        <ul>
          <li>Klinisch redeneren</li>
          <li>MCV communicatie</li>
          <li>Lichamelijk onderzoek</li>
          <li>Farmacologie</li>
          <li>DEPZ (Diversiteit, Ethiek & Recht, Patiëntveiligheid in de Zorg)</li>
        </ul>
        <br /><br />
        Klik hieronder op het desbetreffende jaar waarvoor je STAT doet.
      </div>
      {/* Lessen */}
      <div className='lessen'>
        <hr className='leslijn' />
        <div className="knoppenlijst">
          <Link className="knop basis" to="./jaar1">Jaar 1</Link>
          <Link className="knop basis" to="./jaar2">Jaar 2</Link>
          <Link className="knop basis" to="./jaar3">Jaar 3</Link>

          {/* <button className="knop">STAT</button> */}
        </div>
      </div>
    </div>
  );
};

export default InfoPagina;
