import React, { useState } from 'react';

import './page-css/Registration.css'
import { useNavigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const LoginForm: React.FC = () => {
  const [naam, setNaam] = useState('');
  const [email, setEmail] = useState('');
  const [blacklist, setBlacklist] = useState('');
  const [studentnummer, setStudentnummer] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [herhaal, setHerhaal] = useState('');
  const [studie, setStudie] = useState('Geneeskunde');
  const [year, setYear] = useState('Bachelor Jaar 1');
  const [termsAndServices, setTermsAndServices] = useState(false);
  const [error, setError] = useState('');

  const yearOptions = [
    'Bachelor jaar 1',
    'Bachelor jaar 2',
    'Bachelor jaar 3',
    'Wachttijd',
    'Master jaar 1',
    'Master jaar 2',
    'Master jaar 3',
    'Anders'
  ];

  const studieOptions = [
    'Geneeskunde',
    'Anders'
  ]

  const navigate = useNavigate();

  const validateNumbers = (value: string) => {
    const regex = /^[0-9]+$/;
    return regex.test(value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  
    const formData = {
      naam: naam,
      email: email,
      studentnummer: studentnummer,
      wachtwoord: wachtwoord,
      studie: studie,
      year: year,
      termsAndServices: termsAndServices,
    };

    console.log(formData)

    // Iterate over the form fields and check if each field is empty
    for (const [key, value] of Object.entries(formData)) {
        if (value === '') {
        // Display an error message to the user and prevent the form from being submitted
        setError('Alle velden dienen ingevuld te worden');
        return;
        }
    }

    if (herhaal !== wachtwoord) {
        // Set the error message
        setError('Wachtwoorden komen niet overeen.');
        return;
      }

    if (!email.includes('@')) {
        // Set the error message
        setError('Dit is geen geldig e-mailadres');
        return;
      }
    
    if (!(/^\d{7,8}$/.test(studentnummer))) {
        setError('Een studentnummer moet uit 7 of 8 cijfers bestaan');
        return;
    }

    if (!validateNumbers(studentnummer)) {
        // Display an error message to the user
        setError('Een studentnummer kan alleen cijfers bevatten');
        return;
      }
    
    if (!termsAndServices) {
        // Set the error message
        setError('Je moet akkoord gaan met de terms en services.');
        return;
      }
  
    const response = await fetch('https://SpringLabVU.pythonanywhere.com/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
        // The response was successful
        setError('Geen verbinding met de server.')
    }
  
    const data = await response.json();

    setBlacklist('false');
  
    if (data.message === 'Registration successful') {
      // Redirect the user to the login page
      localStorage.setItem("studentnummer", studentnummer);
      localStorage.setItem("naam", naam);
      localStorage.setItem("email", email);
      localStorage.setItem("blacklist", blacklist);
      const admin = '0';
      localStorage.setItem("admin", admin);
      navigate('/', { replace: true });
      window.location.reload();
    } else {
      // Show an error message to the user
      setError(data.message)
    }
  };
  

  return (
    <div className='login-container'>
        <h1 className="page-title">Registratie</h1>
        <form className="registration-form" onSubmit={handleSubmit}>
        <input
            type="naam"
            name="naam"
            placeholder="Volledige naam"
            value={naam}
            onChange={(event) => setNaam(event.target.value)}
        />
        <input
        type="email"
        name="email"
        placeholder="Email (Voorkeur studentenmail)"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        />
        <input
            type="studentnummer"
            name="studentnummer"
            placeholder="Studentnummer"
            value={studentnummer}
            onChange={(event) => setStudentnummer(event.target.value)}
        />
        <input
            type="password"
            autoComplete="new-password"
            name="wachtwoord"
            placeholder="Wachtwoord"
            value={wachtwoord}
            onChange={(event) => setWachtwoord(event.target.value)}
        />
        <input
            type="password"
            autoComplete="new-password"
            name="herhaal"
            placeholder="Herhaal wachtwoord"
            value={herhaal}
            onChange={(event) => setHerhaal(event.target.value)}
        />
        <label className='register-tekst'>Studie</label>
        <select
            name="studie"
            value={studie}
            onChange={(event) => setStudie(event.target.value)}
        >
            {studieOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
            ))}
        </select>
        <label className='register-tekst'>Studiejaar</label>
        <select
            name="year"
            value={year}
            onChange={(event) => setYear(event.target.value)}
        >
            {yearOptions.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
            ))}
        </select>
        <label htmlFor="termsAndServices">I accept the <Link to="/TermsAndServices">terms and services.*</Link></label> 
        <input
            type="checkbox"
            name="termsAndServices"
            checked={termsAndServices}
            onChange={(event) => setTermsAndServices(event.target.checked)}
        />
        <button type="submit">Register</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>

    </div>
  );
};

export default LoginForm;
