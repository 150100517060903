import React, { useState } from 'react';
import axios from 'axios';
import './page-css/Registration.css';

const NewActivity: React.FC = () => {
  const [title, setTitle] = useState(''); 
  const [date, setDate] = useState('');
  const [slots, setSlots] = useState('');
  const [time, setTime] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [team, setTeam] = useState(''); 
  const [response, setResponse] = useState('');

  // Security for admin only page
  const admin_string = localStorage.getItem("admin") || "";
  const admin_cache = admin_string.replace(/"/g, "");
  const admin = admin_cache === '70f076a4e37da1d0cc8e285966979c2' ? true : false;

  const handleAanmaken = async () => {
    const res = await axios.post('https://SpringLabVU.pythonanywhere.com/add_activity', {
      date,
      time,
      location,
      title,
      description,
      slots,
      team
    });

    setResponse(res.data);

    // Do something with the response from the API.
  };

  return (
    <div>
      {admin && (
      <div className="registration-container">
        <h1 className="page-title">Nieuwe Activiteit</h1>
        <div className='contact-pagina-tekst'>
        </div>
        <form className="registration-form">
          <label htmlFor="title">Titel:</label>
          <input type="text" id="title" name="title" value={title} onChange={(event) => setTitle(event.target.value)} />

          <label htmlFor="date">Datum (Format: 2024-01-22):</label>
          <input type="naam" id="date" name="date" value={date} onChange={(event) => setDate(event.target.value)} />

          <label htmlFor="date">Tijd (Format: 19:00):</label>
          <input type="naam" id="time" name="time" value={time} onChange={(event) => setTime(event.target.value)} />

          <label htmlFor="date">Locatie (Format: KTC 4-006):</label>
          <input type="naam" id="locatie" name="locatie" value={location} onChange={(event) => setLocation(event.target.value)} />

          <label htmlFor="date">Plekken beschikbaar:</label>
          <input type="naam" id="slots" name="slots" value={slots} onChange={(event) => setSlots(event.target.value)} />

          <label htmlFor="description">Beschrijving:</label>
          <textarea id="description" name="description" value={description} onChange={(event) => setDescription(event.target.value)}></textarea>

          <label htmlFor="team">Team:</label>
          <select className='teamselector' id="team" name="team" value={team} onChange={(event) => setTeam(event.target.value)}>
            <option value="">Selecteer een team</option>
            <option value="AcuteGeneeskunde">Acute Geneeskunde</option>
            <option value="Anatomie">Anatomie</option>
            <option value="Radiologie">Radiologie</option>
            <option value="Basisvaardigheden">Basisvaardigheden</option>
            <option value="Duurzaam">Duurzaam</option>
            <option value="Leefstijlgeneeskunde">Leefstijlgeneeskunde</option>
            <option value="Obstetrie">Obstetrie</option>
            <option value="Psychiatrie">Psychiatrie</option>
            <option value="Wetenschap">Wetenschap</option>
            <option value="Promo">Promo</option>
          </select>

          <button type="submit" onClick={handleAanmaken}>Aanmaken</button>
          {response && <p style={{ color: 'green' }}>{response}</p>}
        </form>
      </div>
      )}
    </div>
  );
};

export default NewActivity;
