import React, { useState, useEffect } from 'react';

const App: React.FC = () => {

    const [studentnummer, setStudentnummer] = useState('');
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        // Get the username from the logged-in user
        const studentnummer_string = localStorage.getItem("studentnummer") || "";
        const studentnummer_cache = studentnummer_string.replace(/"/g, "");

        // admin status ophalen
        const admin_string = localStorage.getItem("admin") || "";
        const admin_cache = admin_string.replace(/"/g, "");
        const admin = admin_cache === '70f076a4e37da1d0cc8e285966979c2' ? true : false;
        setAdmin(admin);

        // Update the username state variable
        setStudentnummer(studentnummer_cache);
    }, []);

    console.log('this is excecuted')
    console.log(studentnummer)




    return (
        <div>
            {studentnummer &&
                (<div>
                    <div style={{ width: '100%', height: '600px' }}>
                        <iframe src="https://app.box.com/embed/s/79rm0t5r255ejkjbyzro01wubak8ah7u?expandSidebars=true&showParentPath=false&sortColumn=name"
                            frameBorder="0"
                            style={{ border: '0', width: '100%', height: '100%' }}
                            allowFullScreen
                        />
                    </div>
                </div>)}
            {!studentnummer &&
                (<div>
                    U dient in te loggen om deze content te zien.
                </div>)}
        </div>

    );
};

export default App;
