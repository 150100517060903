import React from 'react';
import logo from './obstetrie_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Obstetrie: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Obstetrie & Gynaecologie</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          De Obstetrie is het vakgebied waarbij de zwangerschap en bevalling van de vrouw centraal staat. De Gynaecologie is een medische specialiteit dat zich bezighoudt met de gezondheidszorg en aandoeningen die specifiek voorkomen bij vrouwen, van jong tot oud, zoals aandoeningen van de baarmoeder en eierstokken. Vanwege de overlap worden deze specialismen in de praktijk vaak samengevoegd.
          <br /><br />
          Bij een coschap Gynaecologie kan je als co-assistent vaak weinig praktische handelingen toepassen. Als Gynaecologie en Obstetrie team van Springlab proberen wij juist de praktische vaardigheden aan jullie aan te bieden. Lessen binnen de gynaecologie as zijn het uitvoeren van gynaecologisch onderzoek, anticonceptie en spiraal plaatsing. Binnen de obstetrie geven wij lessen over echografie van de zwangere vrouw of het begeleiden van een bevalling met hierbij de opvang van de neonaat. De lessen zullen gegeven worden met behulp van simulatiepoppen of als dit mogelijk is vrijwilligers (echografie). De simulatiepoppen zijn zo ontworpen dat er bijvoorbeeld een levensechte bevalling of echo van de foetus nagebootst kan worden!
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop obs" to="./">Spiraal plaatsen</Link>
          <Link className="knop obs" to="./">Epistiotomie (TBA)</Link>
          <Link className="knop obs" to="./">CTG</Link>
          <Link className="knop obs" to="./">Obs/gyn echografie</Link>
          <Link className="knop obs" to="./">Bevalling voor dummies</Link>
          <Link className="knop obs" to="./">Pijnstiling gedurende bevalling</Link>
          <Link className="knop obs" to="./">Bevalling voor gevorderden</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Obstetrie;
