import React, { useState } from 'react';
import './page-css/Registration.css';

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    type: 'contact'
  });

  const [successMessage, setSuccessMessage] = useState<string | null>(null);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch('https://SpringLabVU.pythonanywhere.com/send_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        console.log('Message sent successfully!');
        setSuccessMessage('Uw bericht is met succes verzonden. We nemen zo spoedig modig contact met u op!');
        // Voeg hier verdere logica toe, bijvoorbeeld een succesbericht aan de gebruiker
      } else {
        console.error('Failed to send message.');
      }
    } catch (error) {
      console.error('Error:', error);
    }


  };

  return (
    <div>
      <div className="registration-container">
        <h1 className="page-title">Contactformulier</h1>
        <div className='contact-pagina-tekst'>
          <div className='same-line'>
            <b>Contact opnemen met springlab?</b> Heb je vragen dan kan je het volgende formulier invullen, wij zullen zo snel mogelijk antwoorden.
          </div>
        </div>
        {!successMessage && (
        <form className="registration-form" onSubmit={handleSubmit}>
          <label htmlFor="name">Naam:</label>
          <input type="text" id="name" name="name" onChange={handleChange} />

          <label htmlFor="email">E-mail:</label>
          <input type="email" id="email" name="email" onChange={handleChange} />

          <label htmlFor="phone">Telefoonnummer:</label>
          <input type="tel" id="phone" name="phone" onChange={handleChange} />

          <label htmlFor="message">Bericht:</label>
          <textarea id="message" name="message" onChange={handleChange}></textarea>

          <button type="submit">Versturen</button>
        </form>)}
        <div className='succesmessage'>{successMessage}</div>  

      </div>
    </div>
  );
};

export default Contact;
