import React, { useState } from 'react';
import './page-css/Registration.css'
import { useNavigate, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';


const LoginForm: React.FC = () => {
  const [studentnummer, setStudentnummer] = useState('');
  const [wachtwoord, setWachtwoord] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Validate the email and password
    const formData = {
      studentnummer: studentnummer,
      wachtwoord: wachtwoord,
    };

    // Iterate over the form fields and check if each field is empty
    for (const [key, value] of Object.entries(formData)) {
      if (value === '') {
      // Display an error message to the user and prevent the form from being submitted
      setError('Alle velden dienen ingevuld te worden');
      return;
      }
    }

    const response = await fetch('https://SpringLabVU.pythonanywhere.com/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      // The response was successful
      setError('Geen verbinding met de server.')
    }

    const data = await response.json();

    if (data.message === 'Login successful') {
      // Redirect the user to the login page
      localStorage.setItem("studentnummer", studentnummer);

      // admin status ophalen in python
      const response_profile = await fetch('https://SpringLabVU.pythonanywhere.com/get_profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studentnummer),
      });

      const data = await response_profile.json();
      localStorage.setItem("admin", data.admin);  
      localStorage.setItem("naam", data.naam);
      localStorage.setItem('email', data.email)
      localStorage.setItem('blacklist', data.blacklist)

      
      navigate('/', { replace: true });
      window.location.reload();
    } else {
      // Show an error message to the user
      setError(data.message)
    }

  };

  return (
    <div className='login-container'>
        <h1 className="page-title">Login</h1>
        <form className="registration-form" onSubmit={handleSubmit}>
        <input
            type="studentnummer"
            name="studentnummer"
            placeholder="Studentnummer"
            value={studentnummer}
            onChange={(event) => setStudentnummer(event.target.value)}
        />
        <input
            type="password"
            name="wachtwoord"
            placeholder="Wachtwoord"
            value={wachtwoord}
            onChange={(event) => setWachtwoord(event.target.value)}
        />
        <button type="submit">Login</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
        <div className='same-line login-pagina-tekst'>
            Heb je nog geen account? <Link to="/register">Registreren.</Link>
        </div>
    </div>
  );
};

export default LoginForm;
