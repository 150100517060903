import React from 'react';
import logo from './duurzaam_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Duurzaam: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Duurzame & Planetaire Gezondheid</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Duurzaamheid wordt steeds belangrijker in de zorg. De Nederlandse zorgsector veroorzaakte in 2022 7% van de nationale CO2-voetafdruk. Het is een gegeven dat de gezondheid van onze planeet en die van de mens nauw met elkaar verbonden zijn. Planetary health gaat over de manier waarop menselijke- en planetaire gezondheid elkaar beïnvloeden. Er ligt een sterke focus op het zien van mogelijkheden en vinden van oplossingen die beide kanten op werken. 
          <br /><br />
          Met onze lessen leer je een bepaalde zienswijze die naast de praktische dingen die je leert bij SpringLab ook onmisbaar is voor jou als arts in de praktijk. We bieden inspirerende lessen aan over Planetary Health en Duurzaamheid in de zorg. 
          <br /><br />
          We hopen je snel te zien bij een van onze lessen!
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop duurzaam" to="./">Climate Fresk</Link>
          <Link className="knop duurzaam" to="./">Planetary Health</Link>
          <Link className="knop duurzaam" to="./">Duurzame Zorg</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Duurzaam;
