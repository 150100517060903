// BlacklistPage.tsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Account {
  naam: string;
  // ... other account properties
  email: string;
  studentnummer: string;  
  blacklist: boolean;
  admin: boolean;
}

const Profielen: React.FC = () => {
  const [accounts, setAccounts] = useState<Account[]>([]);

  const admin_string = localStorage.getItem("admin") || "";
  const admin_cache = admin_string.replace(/"/g, "");
  const admin = admin_cache === '70f076a4e37da1d0cc8e285966979c2' ? true : false;

  useEffect(() => {
    // Fetch accounts from the backend
    
    // axios.get('http://127.0.0.1:5000/api/accounts').then((response) => {
    axios.get('https://SpringLabVU.pythonanywhere.com/api/accounts').then((response) => {
      setAccounts(response.data.accounts);
    });
  }, []);

  const handleToggleBlacklist = (index: number) => {
    // Toggle the blacklist status for the selected account
    const updatedAccounts = [...accounts];
    updatedAccounts[index].blacklist = !updatedAccounts[index].blacklist;

    // Update the backend with the new blacklist status
    // axios.put('http://127.0.0.1:5000/api/accounts', { accounts: updatedAccounts }).then(() => {
    axios.put('https://SpringLabVU.pythonanywhere.com/api/accounts', { accounts: updatedAccounts }).then(() => {        
      setAccounts(updatedAccounts);
    });
  };
  
  const copyEmails = () => {
    const emails = accounts.map((account) => account.email).join(', ');
    navigator.clipboard.writeText(emails);
  };

  return (
    <div>
      {admin && (
      <div className='profielen'>
        <h1>Profielen</h1>
        <button className='button' onClick={copyEmails}>Kopiëer E-mail Adressen</button>
        <div>
        <table className='profielen-table'>
            <thead>
            <tr>
                <th>Naam</th>
                <th>E-mail</th>
                <th>Studentnummer</th>
                <th>Admin</th>
                {/* Add other table headers as needed */}
                <th>Blacklist</th>
            </tr>
            </thead>
            <tbody>
            {accounts.map((account, index) => (
                <tr key={index}>
                <td>{account.naam}</td>
                <td>{account.email}</td>
                <td>{account.studentnummer}</td>
                <td>{account.admin ? 'Yes' : 'No'}</td>

                {/* Display other account information as needed */}
                <td>
                    <input
                    type="checkbox"
                    checked={account.blacklist}
                    onChange={() => handleToggleBlacklist(index)}
                    />
                </td>
                </tr>
            ))}
            </tbody>
        </table>
        </div>  
      </div>
      )}
    </div>
  );
};

export default Profielen;
