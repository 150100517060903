import React from 'react';
import logo from './overzicht_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Overzicht: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-titel'>
        <h1>Overzicht teams</h1>
      </div>
      <div className='pagina-logo'>
        <img src={logo} alt="SpringLab Logo" className='overzicht-pagina-logo-inn'/>
      </div>

      <div className='contact-pagina-tekst'>
        <p>
        <br /><br />
        Springlab bestaat uit een projectleider, een bestuur en 10 leerlijnen met teamleden en student-assistenten. 
        <br /><br />
        Samen zorgen zij ervoor dat alle workshops en samenwerkingen met andere partijen zoals de opleiding en het ziekenhuis vloeiend verlopen.
        <br /><br />
        <br /><br />
        {/* Acute geneeskunde: geeft ATLS- en ALS-workshops en gaat alle letters van het ABCDE-protocol af. Ook houdt dit team zich bezig met de SIM-Challenge, een internationale simulatiecompetitie. 
        A: Airway (Anesthesioloog)
        B: Breathing (Longarts)
        C: Circulation (Cardioloog)
        D: Disability (Neurologie)
        ALS 
        ATLS
        <br /><br />
        <br /><br />
        Anatomie & Fysiologie: Met behulp van de Anatomage tafel, een digitale snijtafel, worden e-learnings gemaakt, lessen gegeven binnen Springlab maar wordt er  ook samengewerkt met de anatomie sectie van de Bachelor om daar de tafel te implementeren. 
        Trauma anatomie bovenste extremiteit
        HPB chirurgie
        <br /><br />
        <br /><br />
        Basisvaardigheden: Lessen over praktische vaardigheden die je als toekomstig dokter zeker gaat inzetten, denk hierbij aan hechten, venapunctie of infuusprikken. 
        Infuus prikken
        Hechten
        Venapunctie
        OefenSTAT
        <br /><br />
        <br /><br />
        Communicatie: De promo-posts voor elke les, het bijhouden van de socials en de website en eventuele designs die ergens voor nodig zijn worden allemaal beheerd door het promo-team. 
        <br /><br />
        <br /><br />
        Duurzaamheid & planetary health: houdt zich bezig met duurzaamheid in de zorg en het verband tussen menselijke gezondheid, ecosystemen en de gezondheid van de aarde. Dit team zet zich veel in, in het onderwijs en is betrokken bij het herzien van  de bachelor geneeskunde.
        Planetary health
        Duurzame zorg
        Climate fresk
        <br /><br />
        <br /><br />
        Leefstijl: aan de hand van het leefstijlroer geeft dit team lessen over onder andere; slaap, voeding, beweging, zingeving en ontspanning. Daarnaast zijn er samenwerkingen met andere teams, zoals de les Sportcardiologie met team Radiologie. Ook is het team betrokken bij het herzien van de bachelor geneeskunde.
        Leefstijl op recept: Hypertensie
        Effecten van beweging
        Slaap, voeding en beweging
        Sportvoeding
        Leefstijl van de student
        De eerste 1000 dagen
        Slaap
        Sportcardiologie
        <br /><br />
        <br /><br />
        Obstetrie & gynaecologie: lessen voor beginners en gevorderde, allemaal rondom het onderwerp Gynaecologie! Er worden lessen gegeven zoals; De Bevalling en Complicaties tijdens de Bevalling. Maar ook echografie bij zwangeren, waarbij zwangeren vrouwen langskomen waar op geoefend mag worden met echoën.
        Spiraal plaatsen
        Echografie bij zwangeren
        Bevallen voor dummies
        Complicaties tijdens de bevalling
        <br /><br />
        <br /><br />
        Psychiatrie: houdt zich bezig met belangrijke onderwerpen  binnen de psychiatrie. Door middel van lessen zoals Eetstoornissen, Psychedelica en (in samenwerking met team Gynaecologie) Postpartum Depressie, worden essentiële onderwerpen en hot topics behandeld als een perfecte  aanvulling op de bachelor en master.
        Sollicitatietips
        Psychedelica
        Eetstoornissen
        <br /><br />
        <br /><br />
        Radiologie: geeft hands-on-lessen waarbij studenten leren echoën van bijvoorbeeld  het abdomen, de longen en het hart. Daarnaast geeft dit team workshops over het beoordelen van CT-scans en eFAST-lessen (het echoën in acute situaties).
        eFAST
        Hartechografie
        Longechografie
        Abdomen echografie
        Sportcardiologie
        <br /><br />
        <br /><br />
        Wetenschap: voor hulp bij je thesis en wetenschappelijke stage. Door middel van een reeks van 4 lessen worden studenten van begin tot eind geholpen bij het schrijven en opzetten van hun onderzoek en geven ze feedback op elkaar.
        WS1: Background, backbone en methods
        WS2: Statistics, results & discussion
        WS3: Writing techniques & academic style
        WS4: Practical tips for publishing en publication pressure

         */}
        </p>
      </div>

      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop acuut" to="./">Opfriscursus EHO/BLS (TBA)</Link>
          <Link className="knop acuut" to="./">ATLS (TBA)</Link>
          <Link className="knop acuut" to="./">Masterclasses ABCDE (TBA)</Link>
          <Link className="knop acuut" to="./">Extreme Geneeskunde (TBA)</Link>
          
        </div>
      </div> */}
      {/* <button className="knop">STAT</button> */}
    </div>
  );
};

export default Overzicht;
