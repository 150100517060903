import React from 'react';
import logo from './acute_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const AcuteGeneeskunde: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
        <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Acute Geneeskunde Team</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Team Acute Geneeskunde heeft een breed interessegebied. Het team bestaat uit studenten van alle jaarlagen die allemaal bijdragen aan het organiseren van onze lessen. Zo organiseren we lessen van BLS tot Masterclasses ATLS. Voor veel van onze lessen werken we samen met enthousiastelingen van de afdeling Anesthesiologie.
          <br /><br />
          In onze lessen proberen we informatie te balanceren met (inter)actie. Het doel: na elke workshop voel je jezelf bekwamer in Acute Geneeskunde bij het verlaten van ons lokaal!
        </p>
      </div>

      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop acuut" to="./">Opfriscursus EHO/BLS (TBA)</Link>
          <Link className="knop acuut" to="./">ATLS (TBA)</Link>
          <Link className="knop acuut" to="./">Masterclasses ABCDE (TBA)</Link>
          <Link className="knop acuut" to="./">Extreme Geneeskunde (TBA)</Link>
          
        </div>
      </div> */}
      {/* <button className="knop">STAT</button> */}
    </div>
  );
};

export default AcuteGeneeskunde;
