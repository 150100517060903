import React from 'react';
import logo from './page-images/yulam.jpeg';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const YuShrine: React.FC = () => {
    return (
        <div className='pagina-div'>

            <div className='pagina-titel'>
                <h1>Yu Lam Shrine</h1>
            </div>
            <div className='pagina-logo'>
                <img src={logo} alt="SpringLab Logo" className='overzicht-pagina-logo-inn'/>
            </div>
            <div className='contact-pagina-tekst'>
                <p>
                    Ode aan onze (mede)oprichter Yu Lam<br></br><br></br>
                </p>
            </div>
        </div>
    );
};

export default YuShrine;
