import React from 'react';
import logo from './anatomie_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Anatomie: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Anatomie & Fysiologie Team</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Team Anatomie & Fysiologie is het nieuwste team binnen SpringLab, maar erg ambitieus! Met de aankoop van twee Anatomage tafels (Google maar even!) willen we zowel curriculaire als extra-curriculaire lessen ontwikkelen op het gebied van Anatomie en Fysiologie. Dit alles doen we in nauwe samen met de afdeling Anatomie van de opleiding.
          <br/><br/>
          Daarnaast werken we veel samen met de andere teams binnen SpringLab en hun partners, aangezien anatomie en fysiologie de pilaren zijn van de geneeskunde. Een goede kennis van deze vakkengebieden vormen de basis in de ontwikkeling van jou tot arts.
          <br/><br/>
          De resultaten van dit team zul je terugvinden in de opleiding door integratie van de Anatomage in practica, zelfstudie-opdrachten, opfriscursussen en diverse samenwerkingen. Geen enkel ander team heeft zo'n diverse bijdrage aan of diverser lesaanbod als team Anatomie & Fysiologie. Ons doel: het fundament van iedere student te verstevigen om zo'n fantastisch mogelijke arts te kunnen worden!
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop anatomie" to="./">Anatomage (TBA)</Link>
          <Link className="knop anatomie" to="./">Opfriscursus Anatomie (TBA)</Link>
          <Link className="knop anatomie" to="./">Fysiologie 101 serie (TBA)</Link>
          <Link className="knop anatomie" to="./">Pathofysiologie (TBA)</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Anatomie;
