import React from 'react';
import logo from './leefstijl_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Leefstijl: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Leefstijlgeneeskunde</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Leefstijlgeneeskunde is een vrij nieuwe tak binnen de geneeskunde, die zich bezighoudt met preventie van ziekte door middel van leefstijlinterventies. Ook is het gefocust op het bestrijden van symptomen en het terugdringen van medicatie en dat allemaal door middel van een gezonde levenswijze.
          <br /><br />
          We ontwikkelen met Team Leefstijl verschillende lessen, zoals lessen over slaap, voeding en beweging, maar ook specifieker gericht op bepaalde ziektebeelden, zoals leefstijlinterventies voor hypertensie en diabetes. Daarnaast kijken we naar interessante trends binnen de wereld van leefstijl en proberen wij deze wetenschappelijk te benaderen.
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop leef" to="./">"Leefstijl op recept"-serie</Link>
          <Link className="knop leef" to="./">"Masterclass"-serie</Link>
          <Link className="knop leef" to="./">VU in Motion (TBA)</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Leefstijl;
