import React from 'react';
import logo from './wetenschap_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Wetenschap: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Wetenschap</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Team Wetenschap heeft als doel het op weg helpen van studenten die meer willen weten over het uitvoeren / doen van onderzoek.  Samen met onze (MD-phd) student-assistenten creëren wij activiteiten om jou op weg te met onderzoek doen en het verdiepen in (medische) wetenschap. Van een bachelor- of masterthesis of ander onderzoek waar je aan werkt, tot het leren van professionals uit het veld!
          <br /><br />
          We proberen het 'onderzoek-doen' dichter naar jou toe te brengen. Dit doen we op 4 verschillende manieren:
          <br /><br />
          1. 'Wetenschap (WS)'-workshops: Eigen gemaakt onderwijs rondom het inlezen, opzetten, schrijven én publiceren van onderzoek.<br />
          2. 'State of the Art'-sessies: hierin worden onderzoekers uitgenodigd om beknopt hun baanbrekend onderzoek te presenteren (binnen en buiten het AUMC), waarna er een Q&A volgt.<br />
          3. 'Room for Discussion'-sessies: hierin worden (spraakmakende) professionals met verschillende achtergronden bijeengebracht voor een paneldiscussie met het publiek (a.k.a. studenten). De bedoeling is tot een mooi (wetenschappelijk) debat te komen!<br />
          4. 'Onderzoeksmakelaar': Samen met de Facultaire StudentenRaad (FSR) en Strategie & Beleid Onderwijs (SBO) van de Medische Faculteit VU, zijn we als team bezig tot het realiseren van een digitale plek waarop professionals uit het (onderzoeks)veld onderzoeksvacatures kunnen plaatsen. Zo moet het voor jullie als student eenvoudiger en inzichtelijker moeten zijn om onderzoeksbegeleiders of een bijbaan als onderzoeksassistent te vinden!<br />
          <br /><br />
          Houd onze pagina in de gaten voor eventuele updates! Wij zijn overigens ontzettend geïnteresseerd hoe jullie over onze plannen denken! Help ons door middel van het invullen van de volgende enquête: https://forms.gle/sqPByYtY6F66tghn7
         </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop weten" to="./">WS-Workshops</Link>
          <Link className="knop weten" to="./">State of the Art (TBA)</Link>
          <Link className="knop weten" to="./">Room for Discussion (TBA)</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Wetenschap;
