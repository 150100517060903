import React from 'react';
// import logo from './overzicht_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const AboutUs: React.FC = () => {
    return (
        <div className='pagina-div'>
            {/* <div className='pagina-logo'>
                <img src={logo} alt="SpringLab Logo" className='overzicht-pagina-logo-inn'/>
            </div> */}
            <div className='pagina-titel'>
                <h1>Ontstaan</h1>
            </div>
            <div className='contact-pagina-tekst'>
                <p>
                    In de facultaire onderwijsagenda van de opleiding Geneeskunde aan de Vrije Universiteit Amsterdam voor het academiejaar 2018-2019 werden prioriteiten vastgesteld voor het toekomstbestendig maken van de opleiding. Bij klankbordsessies werd geïnventariseerd naar de behoefte van de student; wat er miste in de opleiding of wat een aanvulling zou kunnen zijn op het huidige curriculum. Hieruit kwam naar voren dat er vraag was naar de integratie van verschillende kennisniveaus en de mogelijkheid om opgedane kennis direct toe te passen in de praktijk. Als aanbeveling werd het idee van een “skillslab” geopperd, een ruimte waar studenten "kunnen leren wat zij zelf willen leren" en waar onderwijs wordt vormgegeven "van, voor en door studenten". Deze plek is nu bekend als SpringLab: een innovatief fysiek extracurriculair onderwijs platform!
                    <br></br><br></br>
                </p>
            </div>
            <div className='pagina-titel'>
                <h1>Visie</h1>
            </div>
            <div className='contact-pagina-tekst'>
                <p>
                    Onze doelstellingen focussen zich op het faciliteren van een innovatief fysiek onderwijsplatform dat is ontwikkeld "van, voor en door studenten". Dit begon als extra-curriculaire workshops waar studenten in de avonduren naar toe konden om hun kennis bij te spijkeren over thema’s die in de bachelor en master minder centraal staan. Maar wat in 2023 is uitgegroeid tot het integreren van onze kennis en materialen in het huidige curriculum, waaronder meer duurzaamheid en leefstijl in het curriculum en het gebruik van de Anatomage tafel bij snijzaal practica.
                    We streven naar onderwijs waar ruimte is om te kunnen experimenteren, waarbij het centraal staat dat studenten zelf kunnen bepalen hoe en wanneer ze deze kennis opdoen. Op deze manier hopen we een waardevolle bijdrage te leveren aan de verbetering van de geneeskundeopleiding aan de VU, waarbij we inspelen op de behoeften en wensen van de studenten. En dit hopelijk nog lang mogen doen.
                </p>
            </div>
        </div>
    );
};

export default AboutUs;
