import React from 'react';
import logo from './psychiatrie_logo.png';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const Psychiatrie: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-logo'>
      <img src={logo} alt="SpringLab Logo" className='pagina-logo-inn'/>
      </div>
      <div className='pagina-titel'>
        <h1>Psychiatrie & Persoonlijke Ontwikkelinge</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Psychiatrie is de tak van de geneeskunde die zich bezighoudt met de diagnose, behandeling en preventie van geestelijke gezondheidsproblemen. Dit omvat zowel psychische aandoeningen zoals depressie en angststoornissen als verslavingen en gedragsproblemen. Persoonlijke ontwikkeling is het proces waarbij mensen zichzelf beter leren kennen en groeien als individuen, zowel op emotioneel, mentaal als fysiek vlak. Persoonlijke ontwikkeling is daarom essentieel voor het worden van een goede arts.
          <br /><br />
          In deze tweetakkige leerlijn organiseren bij vanuit SpringLab verschillende lessen. Bij de tak psychiatrie kan je denken aan lessen over bijvoorbeeld zelfmoordpreventie, forensische psychiatrie, maar ook over psychedelics en andere hot topics binnen de psychiatrie. Lessen die wij in het kader van persoonlijke ontwikkeling verzorgen gaan onder andere over sollicitatietips, balans tussen studie en privé, studeertips, maar ook over het opdoen van zelfkennis.
        </p>
      </div>
      {/* Lessen */}
      {/* <div className='lessen'>
        <hr className='leslijn' />
        <h2 className='lestitel'>Lessen</h2>
        <div className="knoppenlijst">
          <Link className="knop psy" to="./">Psychadelica</Link>
          <Link className="knop psy" to="./">Sollicitatietips</Link>
          <Link className="knop psy" to="./">Nutritional Psychiatry (TBA)</Link>
          <Link className="knop psy" to="./">28 day challange</Link>
          <Link className="knop psy" to="./">Curriculaire samenwerkingen (TBA)</Link>
        </div>
      </div> */}
    </div>
  );
};

export default Psychiatrie;
