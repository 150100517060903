import React from 'react';

const InfoPagina: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-titel'>
        <h1>Venapunctie</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Team Acute Geneeskunde heeft een breed interessegebied. Het team bestaat uit studenten van alle jaarlagen die allemaal bijdragen aan het organiseren van onze lessen. Zo organiseren we lessen van BLS tot Masterclasses ATLS. Voor veel van onze lessen werken we samen met enthousiastelingen van de afdeling Anesthesiologie.
          <br /><br />
          In onze lessen proberen we informatie te balanceren met (inter)actie. Het doel: na elke workshop voel je jezelf bekwamer in Acute Geneeskunde bij het verlaten van ons lokaal!
        </p>
      </div>
    </div>
  );
};

export default InfoPagina;
