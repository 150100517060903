import React from 'react';

const InfoPagina: React.FC = () => {
  return (
    <div className='pagina-div'>
      <div className='pagina-titel'>
        <h1>Hechten</h1>
      </div>
      <div className='pagina-tekst'>
        <p>
          Hier komen een aantal video's die een basis voor goed hechten vormen.
        </p>
      </div>
    </div>
  );
};

export default InfoPagina;
