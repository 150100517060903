import React, { useState, useEffect } from 'react';
import largeImage from './page-images/wall.jpg';
import demo from './page-images/demo.jpg';
import mri from './page-images/mri.png';
import ambulance from './page-images/ambulance.png';
import stetho from './page-images/stethoscoop.png';
import './page-css/ImageGrid.css';
import { Link } from 'react-router-dom';



const Home: React.FC = () => {
  const [teamCount, setTeamCount] = useState(0);
  const [courseCount, setCourseCount] = useState(0);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [goalCount, setGoalCount] = useState(0);

  const finalValues = {
    teamCount: 10,
    courseCount: 100,
    employeeCount: 84,
    goalCount: 1,
  };

  useEffect(() => {
    const teamInterval = setInterval(() => {
      setTeamCount((prevCount) => Math.min(prevCount + 1, finalValues.teamCount));
    }, 350); // Adjust the interval duration for teams

    const courseInterval = setInterval(() => {
      setCourseCount((prevCount) => Math.min(prevCount + 1, finalValues.courseCount));
    }, 45); // Adjust the interval duration for courses

    const employeeInterval = setInterval(() => {
      setEmployeeCount((prevCount) => Math.min(prevCount + 1, finalValues.employeeCount));
    }, 80); // Adjust the interval duration for employees

    const goalInterval = setInterval(() => {
      setGoalCount((prevCount) => Math.min(prevCount + 1, finalValues.goalCount));
    }, 1000); // Adjust the interval duration for goals

    // Clear the intervals when the component unmounts
    return () => {
      clearInterval(teamInterval);
      clearInterval(courseInterval);
      clearInterval(employeeInterval);
      clearInterval(goalInterval);
    };
  }, []);


  return (
    <div>
      <div className='home-div'>
        <h1>Welkom bij SpringLab</h1>
        {/* <p>Onze missie is om studenten te ondersteunen bij hun leerreis en ze te voorzien van de benodigde middelen en activiteiten.</p> */}
        <p>Deze website is nog onder constructie, in de aankomende maanden zal deze officieel in gebruik genomen worden.</p>
      </div>
      <div className='home-content'>
        <div className="image-container">
          <img src={largeImage} alt="Large Cover" className="home-image" />
          <div className='image-gallery'>
            <img src={demo} alt="Image 2" className="home-image" />
          </div>
        </div>
        <div className='mobile-info'>
          Via deze website kun je inloggen en je aanmelden voor een cursus.
        </div>



        <footer className="mobile-footer">
          <div className="footer-content">
            <div className="footer-links">
              <ul>
                <li><a href="#"></a></li>
                <li><a target="_blank" href="https://linktr.ee/SpringLabVU">LinkTree</a></li>
                <li><a target="_blank" href="https://www.instagram.com/springlabvu/">Instagram</a></li>
                <li><Link to="/contact"><a>Contact Springlab</a></Link></li>
                <li><Link to="/activities"><a>Activiteiten</a></Link></li>
              </ul>
            </div>
            <div className="copyright">
            <p>&copy; 2024 Springlab VU. All Rights Reserved.</p>
          </div>
          </div>
        </footer>

          

        <div className="home-text-bar">
          <div className='home-text'>
            <p>{goalCount} doel</p>
            <p>{teamCount} teams</p>
            <p>{courseCount}+ cursussen</p>
            <p>{employeeCount} werknemers</p>
          </div>
        </div>
        {/* image grid */}
        <div className='grid'>
          <div className="image-grid">
            <div className="image-cell">
              <a href="/teams/acute-geneeskunde">
                <img src={ambulance} alt="Foto 1" />
              </a>
            </div>
            <div className="image-cell">
              <a href="/teams/basisvaardigheden">
                <img src={stetho} alt="Foto 2" />
              </a>
            </div>
            <div className="image-cell">
              <a href="/teams/radiologie">
                <img src={mri} alt="Foto 2" />
              </a>
            </div>
            {/* Voeg hier nog 6 cellen toe voor de rest van de foto's */}
          </div>
        </div>
        <footer className="desktop-footer">
          <div className="desktop-footer-content">
            <div className="desktop-footer-section">
              <h3>Pagina's</h3>
              <ul className="desktop-footer-links">
                <li><Link to="/"><a>Home</a></Link></li>
                <li><Link to="/activities"><a>Activiteiten</a></Link></li>
                <li><Link to="/profiel"><a>Account</a></Link></li>
              </ul>
            </div>
            <div className="desktop-footer-section">
              <h3>Socials</h3>
              <ul className="desktop-footer-links">
                <li><Link to="https://nl.linkedin.com/company/springlab-vu"><a>LinkedIn</a></Link></li>
                <li><Link to="https://www.instagram.com/springlabvu/"><a>Instagram</a></Link></li>
                <li><Link to="https://www.studioovu.nl/extracurriculaire+activiteiten/springlab/default.aspx"><a>Studio Vu</a></Link></li>
              </ul>
            </div>
          </div>
          <div className="copyright">
            <p>&copy; 2024 Springlab VU. All Rights Reserved.</p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Home;
