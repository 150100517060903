import React from 'react';

const TermsAndServices: React.FC = () => {
  return (
    <div>
     <div className='termsandservices'>
      <h1>Algemene Voorwaarden</h1>

      <h2>1. Aanvaarding van de Voorwaarden</h2>
      <p>
        Door deze website te gebruiken, gaat u akkoord met de volgende voorwaarden. <br></br> Als u niet akkoord gaat met 
        een van deze voorwaarden, gebruik deze website dan niet.
      </p>

      <h2>2. Gebruik van de Website</h2>
      <p>
        U stemt ermee in deze website alleen te gebruiken voor wettige doeleinden en op een manier die geen <br></br>
        inbreuk maakt op de rechten van derden of het gebruik en genot van deze website belemmert.
      </p>

      <h2>3. Nieuwsbrief</h2>
      <p>
        Door u aan te melden voor onze nieuwsbrief, stemt u ermee in regelmatig updates en nieuws over onze
        diensten te ontvangen.
      </p>

      <h2>4. Wijzigingen in de Voorwaarden</h2>
      <p>
        We behouden ons het recht voor deze voorwaarden op elk moment te wijzigen. Het is uw <br></br>
        verantwoordelijkheid om regelmatig te controleren op wijzigingen. Door gebruik te maken van deze <br></br>
        website na dergelijke wijzigingen, stemt u in met de aangepaste voorwaarden.
      </p>

      <p>Laatst bijgewerkt: 09 januari 2024</p>
      </div>  
    </div>
  );
};

export default TermsAndServices;
